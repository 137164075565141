const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/faq"],
    exact: true,
    component: "FAQ",
  },
  {
    path: ["/o-nas"],
    exact: true,
    component: "AboutUs",
  },
  {
    path: ["/blog"],
    exact: true,
    component: "Blog",
  },
  {
    path: ["/cennik"],
    exact: true,
    component: "PriceList",
  },
  {
    path: ["/kontakt"],
    exact: true,
    component: "Contact",
  },
];

export default routes;
